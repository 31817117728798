import React from 'react'
import styles from './Info.module.scss'

const InfoCard = ({title , des , img , color , index}) => {
  return (
    <div className={styles.card_con} style={{backgroundColor: color}}>
      <div className={styles.title}>{title}</div>
      <div className={styles.des}>{des}</div>
      <img className={styles.img} src={img} alt={`img_${index}`}/>
    </div>
  )
}

const infoData = [
  {
    title : 'Delivery Service',
    des : 'need to send something from Korea to Thailand ?',
    img : '/info/delivery.png',
    color : '#FF5D47'
  },
  {
    title : 'Pre-Order',
    des : 'need to buy something from Korean website ?',
    img : '/info/pay.png',
    color : '#FF820F'
  },
  {
    title : 'Truck Pick-up',
    des : 'need some pick-up service for your cargo ?',
    img : '/info/truck.png',
    color : '#FFB800'
  },
]

const Info = () => {
  return (
    <div className={`${styles.info} ${styles.page_con}`}>
      <img className={styles.logo} src="/logo.png" alt="logo"/>
      <div className={styles.des}>Check out our services</div>

      <div className={styles.card_list}>
        {infoData.map((data, index) => {
          return (
            <InfoCard
              key={index}
              index={index}
              title={data.title}
              des={data.des}
              img={data.img}
              color={data.color}
            />
          )
        })}
        <div className={styles.des} style={{marginTop:'50px'}}>do you need our service ?</div>
        <div className={styles.line_con} onClick={() => {
          window.open(
            "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
            "_blank"
          );
        }}>
          Message us !
          <img src="/info/line.png" alt=""/>
          <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L10 10L2 18" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>

      <div className={styles.footer}>
        <a href="#landing">
          <img src="/footer_logo.png" alt="line" />
        </a>
        <div className={styles.line} />
        <div className={styles.sns}>
          <img
            src="/footer_line.png"
            alt="line"
            onClick={() => {
              window.open(
                "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
                "_blank"
              );
            }}
          />
          <img
            src="/footer_insta.png"
            alt="insta"
            onClick={() => {
              window.open(
                "https://instagram.com/seoul2u.kr?igshid=MjEwN2IyYWYwYw==",
                "_blank"
              );
            }}
          />
          <img
            src="/footer_facebook.png"
            alt="facebook"
            onClick={() => {
              window.open("https://www.facebook.com/seoul2u.kr/", "_blank");
            }}
          />
        </div>
        {/* <h1>Submit | FAQ | Licence | Support | Contact</h1> */}
        <h2>ⓒ2023 SeoulConnex All rights reserved. </h2>
      </div>
    </div>
  )
}

export default Info