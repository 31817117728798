import React, { useRef, useState, useEffect } from "react";

import useStore from "./store";

import ScrollOut from "scroll-out";
import "./globals.scss";
import "./ani.css";
import GNB from "./Component/GNB";
import styles from "./Main.module.scss";
import Landing from "./Component/Landing";
import Counting from "./Component/Counting";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import emailjs from "@emailjs/browser";

import pin from "./s2u_pin.png";

// 리뷰카드
function ReviewCard(props) {
  return (
    <div className={styles.reviewCard}>
      <div className={styles.img_con}>
        <img src={`reviews/review_${props.reviewNo}.png`} alt="" />
      </div>

      <div className={styles.comment_con}>
        <img className={styles.quote_img} src="quote.png" alt="" />

        <div className={styles.comment}>{props.comment}</div>

        <div className={styles.profile_con}>
          <img
            className={styles.avatar}
            src={`avatars/avatar_${props.avatarNo}.png`}
            alt=""
          />
          <div className={styles.typeStar}>
            <div className={styles.customerType}>{props.customerType}</div>
            <img className={styles.stars} src="stars.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

const comments = [
  {
    thai: "เร็วมากเลย ขอบคุณมากค่า",
    eng: "so fast for payment proxy, thank you so much",
  },
  {
    thai: "แพ็คมาแน่หนาดีมากๆเลยค่ะ ประทับใจทุกรอบเลย",
    eng: "Superb, good packaged, so impressed service eveytime",
  },
  {
    thai: "ของมาถึงเรียบร้อยค่า ดีใจมากๆ .. จะกลับมาอุดหนุนอีกนะคะ",
    eng: "so glad I got items, I will order again",
  },
  {
    thai: "แพ็คมาแน่นหนาดีเหมือนเดิมเลยค่ะ .. ประทับใจเสมอ",
    eng: "product packaging didnt broken, well packed",
  },
  {
    thai: "สภาพกล่องที่มาถึงสมบูรณ์100%ค่ะ ไม่มีอะไรเสียหาย",
    eng: "100% perfect box, well packed and nothing broken",
  },
  {
    thai: "มารับถึงโรงแรมเลย ดีมาก เดี๋ยวไปเกาหลีอีกใช้บริการอีกแน่นอนฮ้าบ",
    eng: "pick up from the hotel is so convince. I will use Seoul2u next time I visit Korea",
  },
  {
    thai: "แพ็คมาให้เรียบร้อยอย่างดีเหมือนเดิม ประทับใจทุกรอบที่สั่งเลยค่า",
    eng: "well packed and impressed as usual",
  },
  {
    thai: "Seoul2uไม่เคยทำให้ผิดหวัง แอดมินบริการดีตลอด แนะนำดี สำหรับการใช้ชิปปิ้งครั้งแรกประทับใจมากๆ",
    eng: "Seoul2u never disappoint me, good service, friendly and helpful  advice for first shipping expreince",
  },
  {
    thai: "ห่อมาดีมากๆทุกชิ้นเลยค่ะ ประทับใจมั่ก",
    eng: "every items well packed, so impressed",
  },
  {
    thai: "ได้รับของแล้วนะคะ แพ็คมาเรียบร้อยมากค่ะ",
    eng: "parcel arrived with well packed",
  },
  {
    thai: "กดเพลินมากค่า เหมือนซื้อมาขายแต่ใช้เอง",
    eng: "enjoy shopping with Seoul2u",
  },
  {
    thai: "ได้ของแล้วนะคะ แพ็คมาดีมากๆๆๆเป็นปลื้ม มีสั่งอีกแน่นอนค่า",
    eng: "so happy, parcel arrived safely and well packed. I will ship by Seoul2u again",
  },
  {
    thai: "เพิ่งแนะนำขนส่งกับเพื่อนไปด้วยน้า แนะนำไปหลายคนเลย",
    eng: "just recommended Seoul2u to my friends",
  },
  {
    thai: "ได้รับของแล้วเด้อ ไว้แท้ ",
    eng: "I got all parcel safely, sooo fast",
  },
  {
    thai: "ได้ของแล้วน้า ได้ก่อนเรามาถึงไทยอีกค่ะ ",
    eng: "Parcel was arrived to Thailand before me !",
  },
  {
    thai: "เริ่ดมากค่ะ ไวสุด ประทับใจทุกครั้งที่สั่งเลย",
    eng: "Awesome, fast, so impressed with service everytime",
  },
];

const customerType = [
  "shipping + proxy payment",
  "shipping",
  "shipping + proxy payment",
  "shipping",
  "shipping",
  "shipping+pickup",
  "Pre-order",
  "shipping",
  "shipping",
  "shipping",
  "shipping+proxy payment",
  "shipping",
  "shipping",
  "shipping+pickup",
  "shipping+pickup",
  "pre-order",
];

// 구글지도
const Map = () => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 37.485214,
    lng: 127.121347,
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        mapTypeId="hybrid"
        center={center}
        zoom={17}
      >
        <Marker position={center} icon={pin} />
      </GoogleMap>
    </LoadScript>
  );
};

export default function App() {
  // 스토어 값
  const lang = useStore((state) => state.lang);
  const toEng = useStore((state) => state.toEng);

  function LangText(props) {
    if (lang == "eng") {
      return <div> {props.eng}</div>;
    } else if (lang == "thai") {
      return <div> {props.thai}</div>;
    } else if (lang == "kor") {
      return <div> {props.kor}</div>;
    }
  }

  // 이메일
  const formTag = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ym08q2g",
        "template_d8qtktp",
        formTag.current,
        "F8r5WqKMWs-kyxovX"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("email was sent!");
          // emailSent();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameInputChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailInputChange = (event) => {
    setEmail(event.target.value);
  };
  const handleMessageInputChange = (event) => {
    setMessage(event.target.value);
  };

  function emailSent() {
    alert("email was sent!");
    // setName("");
    // setEmail("");
    // setMessage("");
  }

  useEffect(() => {
    ScrollOut();
  }, []);

  // 슬라이더
  const sliderSettingsPC = {
    infinite: true, // 무한 반복
    slidesToShow: 3, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 개수
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const sliderSettingsMB = {
    infinite: true, // 무한 반복
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 개수
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <GNB />

      <Landing />

      <div className="outter">
        {/* ================================================== */}
        {/* About us */}
        {/* ================================================== */}
        <div className={styles.about} id="about">
          <div className={styles.top_area}>
            <div className={styles.img_side} data-scroll>
              <img src="about_img_01.png" alt="about_img_01" />
            </div>

            <img className={styles.map_img} src="/map.png" alt="map" />

            <div className={styles.text_side} data-scroll>
              <div className={styles.title}>About us</div>
              <div className={styles.paragraph}>
                <LangText
                  thai="พันธมิตรที่ดีที่สุดสำหรับการขนส่งจากเกาหลีมาไทยตั้งแต่ปี 2018"
                  eng="best partner for shipping from Korea to Thailand Since 2018. we
                are helping customers with small to big amount of cargo"
                  kor="Seoul2u는 2018년부터 고객 여러분과 함께하고 았습니다."
                />
              </div>
            </div>
          </div>

          <div className={styles.dialog_box}>
            <div className={styles.dialog} data-scroll>
              <img src="oneStop.png" alt="" />
              <div className={styles.dialog_title}>One-stop Service</div>
              <div className={styles.dialog_paragraph}>
                <LangText
                  eng="From small to large shipments, we handle the entire delivery process to your doorstep in Korea."
                  thai="สำหรับธุรกิจขนาดเล็ก จนถึงขนาดใหญ่
                  ครอบคลุมการให้บริการทุกขั้นตอน เมื่อท่านต้องการสั่งสินค้าจากประเทศเกาหลี"
                  kor="다른 복잡한 일들은 신경쓰지 않으셔도 됩니다. 한국에서 문앞까지 배송의 모든 프로세스를 책임집니다"
                />
              </div>
            </div>
            <div className={styles.dialog} data-scroll>
              <img src="hybrid.png" alt="" />
              <div className={styles.dialog_title}>Hybrid Shipping</div>
              <div className={styles.dialog_paragraph}>
                <LangText
                  thai="บริการที่ยืดหยุ่น เหมาะสมกับสินค้าและความต้องการของลูกค้า
                  ตอบโจทย์ความต้องการได้อย่างลงตัว ไม่ว่าจะงานเล็ก งานใหญ่ เราพร้อมดูแลทุกสินค้า ด้วยความใส่ใจ"
                  eng="flexible service Suitable for products and customer needs
                  meet the needs perfectly Whether it's a small job, big job, we are ready to take care of every product. with care"
                  kor="적은 양부터 대량까지 모든 종류의 배송과 고객의 필요와 요구에 맞춤 서비스를 제공하고 있습니다."
                />
              </div>
            </div>
            <div className={styles.dialog} data-scroll>
              <img src="pro.png" alt="" />
              <div className={styles.dialog_title}>Professional</div>
              <div className={styles.dialog_paragraph}>
                <LangText
                  thai="ดำเนินงานด้วยทีมงานมืออาชีพ
                มีประสบการณ์และความเชี่ยวชาญในการขนส่ง ไปจนถึงดูแลสินค้าของท่าน ปลอดภัยจนถึงมือ"
                  eng="Operate with a team of experts
                Those with logistical experience and know-how Safe in hand to manage products"
                  kor="물류 분야에서 풍부한 경험을 가진 전문 팀원들로 구성되어 있으며, 24시간 동안 항상 고객님을 돕고 있습니다."
                />
              </div>
            </div>
          </div>
        </div>

        {/* ================================================== */}
        {/* Shippings */}
        {/* ================================================== */}
        <div className={styles.shippings} id="service">
          <div className={styles.con}>
            <div className={styles.type_card} data-scroll>
              <div className={styles.text_con}>
                <div className={styles.card_title}>Sea</div>
                <div className={styles.schedule_con}>
                  <img src="clock.png" alt="" />
                  <div className={styles.schedule}>20~25 days</div>
                </div>
              </div>
              <div className={styles.img_con}>
                <img src="ship_2.png" alt="ship" />
              </div>
            </div>
            <div className={styles.type_card} data-scroll>
              <div className={styles.text_con}>
                <div className={styles.card_title}>Air</div>
                <div className={styles.schedule_con}>
                  <img src="clock.png" alt="" />
                  <div className={styles.schedule}>3~5 days</div>
                </div>
              </div>
              <div className={styles.img_con}>
                <img
                  className={styles.img_plane}
                  src="plane_2.png"
                  alt="plane"
                />
              </div>
            </div>
            <div className={styles.des}>
              <div className={styles.title}>Shipping</div>
              <div className={styles.paragraph2}>
                <LangText
                  thai="เราให้บริการจัดส่งทั้งทางเรือและเครื่องบิน สามารถเลือกวิธีการจัดส่งตามความต้องการ ให้คำแนะนำให้เหมาะสมกับสินค้า ค่าจัดส่งและระยะเวลาในการจัดส่ง เรือ มีรอบทุกๆ7-10วัน เครื่องบิน มีรอบทุกสัปดาห์"
                  eng="We provide delivery services by both sea and air, allowing you to choose according to your specific needs, suitable for your products, shipping costs, and delivery time. Shipping by sea is available every 7-10 days, while air freight is available on a weekly basis."
                  kor="저희는 상품에 따라 적합한 운송 방법을 제공하는 서비스를 제공하고 있습니다. 이는 물론 택배 비용과 배송 시간에 따라 선택할 수 있습니다. 플랜이 있으며, 선박을 이용한 배송은 매 7-10일마다 이루어지며, 항공 운송은 매주 수행됩니다."
                />
              </div>
              <button
                className={styles.button}
                onClick={() => {
                  window.open(
                    "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
                    "_blank"
                  );
                }}
              >
                REQUEST
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ================================================== */}
      {/* Unpacking Service */}
      {/* ================================================== */}
      <div className={styles.unpacking}>
        <div className={styles.con}>
          <div className={styles.des}>
            <div className={styles.title}>Unpacking Service</div>
            <div className={styles.paragraph2}>
              <LangText
                thai="บริการรีแพค พร้อมรูปสินค้าอัพเดตส่วนตัว
                  สามารถตรวจสอบสินค้า บริการห่อสินค้าอย่างเหมาะสมกับสินค้าในการขนส่งทางไกล"
                eng="Experience the epitome of smoothness with our repacking service.
                    Enjoy product pictures and personal updates,
                    and rest assured with our expert wrapping for secure long-distance transportation."
                kor="제품 사진, 개인 업데이트가 포함된 재포장 서비스를 경험하세요.
                  장거리 운송에 적합한 포장 서비스를 제공합니다."
              />
            </div>
            <button
              className={styles.button}
              onClick={() => {
                window.open(
                  "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
                  "_blank"
                );
              }}
            >
              REQUEST
            </button>
          </div>
          <div className={styles.img_side} data-scroll>
            <img src="unpacking.png" alt="" />
          </div>
        </div>
      </div>

      {/* ================================================== */}
      {/* Pick up Service */}
      {/* ================================================== */}
      <div className={styles.Pickup}>
        <div className={styles.con}>
          <div className={styles.img_side} data-scroll>
            <img src="pickup_img.png" alt="" />
          </div>
          <div className={styles.des}>
            <div className={styles.title}>Pick up Service</div>
            <div className={styles.paragraph2}>
              <LangText
                thai="บริการเข้ารับสินค้าจากโรงแรม ที่พัก บ้าน
                  มีรถบริการทุกขนาด เพียงแจ้งรายละเอียดงาน เราพร้อมจัดเตรียมเข้ารับได้อย่างราบรื่น"
                eng="Relish our seamless pick-up service from hotels, accommodations, and houses.
                Our fleet includes vehicles of all sizes, and with just a few job details,
                we'll handle the arrangements smoothly, ensuring a hassle-free experience for you."
                kor="호텔, 숙박 시설, 그리고 집으로부터의 원활한 픽업 서비스를 즐겨보세요. 다양한 크기의 차량이 포함된 저희 차량 함대로,
                몇 가지 작업 세부사항만 제공하시면 원활한 준비를 해드리며, 귀하에게 불편 없는 경험을 보장합니다."
              />
            </div>
            <button
              className={styles.button}
              onClick={() => {
                window.open(
                  "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
                  "_blank"
                );
              }}
            >
              Request
            </button>
          </div>
        </div>
      </div>

      {/* ================================================== */}
      {/* Proxy Payment */}
      {/* ================================================== */}
      <div className={styles.Proxy}>
        <div className={styles.con}>
          <div className={styles.des}>
            <div className={styles.title}>Proxy Payment</div>
            <div className={styles.paragraph2}>
              <LangText
                thai="บริการสั่งสินค้าจากเวบเกาหลีทุกเวบ ทำให้การสั่งสินค้าของท่านสะดวก รวดเร็ว และปลอดภัย"
                eng="Experience the utmost convenience, speed,
                and safety with our service that allows you to order products from all Korean websites."
                kor="한국의 모든 웹사이트에서 상품을 주문할 수 있는 우리의 서비스로 최대한의 편리함, 빠른 속도, 그리고 안전성을 경험하세요."
              />
            </div>
            <button
              className={styles.button}
              onClick={() => {
                window.open(
                  "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
                  "_blank"
                );
              }}
            >
              Request
            </button>
          </div>
          <div className={styles.img_side} data-scroll>
            <img src="proxy_img.png" alt="" />
          </div>
        </div>
      </div>

      <div className="outter">
        {/* ================================================== */}
        {/* Product */}
        {/* ================================================== */}
        <div className={styles.products}>
          <div className={styles.title}>Our Products</div>
          <div className={styles.paragraph}>
            <LangText
              thai="ชมสินค้าของเราและจะทำให้ชีวิตของคุณง่ายขึ้น"
              eng="Check out our products and that will make your life easier."
              kor="Seoul2u에서 제공하는 웹 서비스들입니다."
            />
          </div>
          <div className={styles.product_con} data-scroll>
            <div className={styles.product_card}>
              <div
                className={styles.img_con}
                onClick={() => {
                  window.open("https://tracking.seoul2u.co.kr", "_blank");
                }}
              >
                <img src="product_tracking.png" alt="" />
              </div>
              <div className={styles.card_title}>Tracking Search</div>
              <div className={styles.card_des}>
                <LangText
                  thai="ระบบติดตามพัสดุ เมื่อถึงออฟฟิศเกาหลี และจัดส่งในไทย"
                  eng="Experience our seamless parcel tracking system,
                  ensuring a smooth process from arrival at our Korean office to final delivery in Thailand.
                  Stay informed every step of the way, providing you with convenience and peace of mind."
                  kor="한국 사무실 도착부터 태국으로 최종 배송까지 원활한 과정을 보장하는 매끄러운 소포 추적 시스템을 경험하세요.
                  모든 단계에서 정보를 제공하여 편의와 안심을 제공합니다."
                />
              </div>
              <button
                className={styles.button}
                onClick={() => {
                  window.open("https://tracking.seoul2u.co.kr", "_blank");
                }}
              >
                Visit
              </button>
            </div>
            <div className={styles.product_card}>
              <div className={styles.img_con}>
                <img src="product_app.png" alt="" />
              </div>
              <div className={styles.card_title}>Seoul2u App</div>
              <div className={styles.card_des}>
                <LangText
                  thai="สั่งซื้อ ชำระเงิน และติดตามการจัดส่งของคุณได้อย่างง่ายดายด้วยแอพ Seoul2u ของเรา เร็ว ๆ นี้ในปีนี้!"
                  eng="Order, pay and track your deliveries easily with our Seoul2u App. Comming soon this year !"
                  kor="주문부터 결제, 배송추적까지 한번에 Seoul2u 앱에서 관리해보세요. 올해 말에 출시합니다 !"
                />
              </div>
              <button
                className={styles.button}
                style={{ backgroundColor: "#f2f2f2", color: "#7e8699" }}
              >
                Comming Soon
              </button>
            </div>
          </div>
        </div>
        {/* ================================================== */}
        {/* three points */}
        {/* ================================================== */}
        <Counting />
      </div>

      {/* ================================================== */}
      {/* reviews */}
      {/* ================================================== */}
      <div className={styles.reviews}>
        <div className={styles.title}>Reviews</div>
        <div className={styles.paragraph2}>
          <LangText
            thai="ลูกค้าของเรารักบริการของเรา"
            eng="Our Customers Loves our Service"
            kor="우리 고객들은 우리의 서비스를 사랑합니다."
          />
        </div>
        <div>
          <Slider className={styles.slider_PC} {...sliderSettingsPC}>
            {Array.from(Array(16), (e, i) => {
              return (
                <ReviewCard
                  key={i}
                  reviewNo={i + 1}
                  avatarNo={i + 1}
                  comment={lang == "thai" ? comments[i].thai : comments[i].eng}
                  customerType={customerType[i]}
                />
              );
            })}
          </Slider>

          <Slider className={styles.slider_MB} {...sliderSettingsMB}>
            {Array.from(Array(16), (e, i) => {
              return (
                <ReviewCard
                  key={i}
                  reviewNo={i + 1}
                  avatarNo={i + 1}
                  comment={lang == "thai" ? comments[i].thai : comments[i].eng}
                  customerType={customerType[i]}
                />
              );
            })}
          </Slider>

          <a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTIxNDYwMDc3Mzk3OTQ0?story_media_id=2819718474381964987&igshid=MzRlODBiNWFlZA==">
            <span className={styles.text}>see all</span>
            <span className={styles.number}>99+</span>
          </a>
        </div>
      </div>

      {/* ================================================== */}
      {/* contact */}
      {/* ================================================== */}
      <div className={styles.contact}>
        <div className={styles.contact_inner}>
          <div className={styles.title} id="contact">
            Contact
          </div>
          <div className={styles.con}>
            <div className={styles.map_side}>
              <div className={styles.map_con}>
                <Map />
              </div>
              <div className={styles.location_contact_con}>
                <div className={styles.location_con}>
                  <div className={styles.contact_title}>Location</div>
                  <div className={styles.contact_p}>
                    Korea Seoul city Song-pa Disctrict Moon-jung-dong
                  </div>
                  <div className={styles.contact_p}>
                    (Detail address for membership only)
                  </div>
                </div>

                <div className={styles.contact_con}>
                  <div className={styles.contact_title}>Contact info</div>
                  <div className={styles.contact_p}>010-9619-0178</div>
                  <div className={styles.contact_p}>seoul2u.work@gmail.com</div>
                </div>
              </div>
            </div>
            <form
              className={styles.mail_side}
              ref={formTag}
              onSubmit={sendEmail}
            >
              <div className={styles.top_line}>
                <div>
                  <div className={styles.mail_title}>name</div>
                  <input
                    className={styles.mail_form}
                    type="text"
                    placeholder="name"
                    name="message"
                    value={name}
                    onChange={handleNameInputChange}
                  />
                </div>
                <div>
                  <div className={styles.mail_title}>email</div>
                  <input
                    className={styles.mail_form}
                    type="email"
                    placeholder="email"
                    name="message"
                    value={email}
                    onChange={handleEmailInputChange}
                  />
                </div>
              </div>
              <div className={styles.second_line}>
                <div className={styles.mail_title}>message</div>
                <textarea
                  className={`${styles.mail_form} ${styles.message_form}`}
                  placeholder="message"
                  name="message"
                  value={message}
                  onChange={handleMessageInputChange}
                />
              </div>
              <input
                className={styles.button}
                type="submit"
                value="Submit"
                onClick={() => {
                  emailSent();
                }}
              />
            </form>
          </div>
        </div>
      </div>

      {/* ================================================== */}
      {/* footer */}
      {/* ================================================== */}
      <div className={styles.footer}>
        <a href="#landing">
          <img src="footer_logo.png" alt="line" />
        </a>
        <div className={styles.line} />
        <div className={styles.sns}>
          <img
            src="footer_line.png"
            alt="line"
            onClick={() => {
              window.open(
                "https://liff.line.me/1645278921-kWRPP32q/?accountId=seoul2u",
                "_blank"
              );
            }}
          />
          <img
            src="footer_insta.png"
            alt="insta"
            onClick={() => {
              window.open(
                "https://instagram.com/seoul2u.kr?igshid=MjEwN2IyYWYwYw==",
                "_blank"
              );
            }}
          />
          <img
            src="footer_facebook.png"
            alt="facebook"
            onClick={() => {
              window.open("https://www.facebook.com/seoul2u.kr/", "_blank");
            }}
          />
        </div>
        {/* <h1>Submit | FAQ | Licence | Support | Contact</h1> */}
        <h2>ⓒ2023 SeoulConnex All rights reserved. </h2>
      </div>
    </>
  );
}
