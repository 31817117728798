import React from "react";

// import landing_img from "./../../public/landing_img.png";
import styles from "./Landing.module.scss";

export default function Landing() {
  return (
    <div className={styles.background} id="landing">
      <div className={styles.text_box}>
        <p className={styles.text}>
          Your Best <br /> Shipping Partner
        </p>
      </div>
      <a className={styles.button} href="#about">
        see more
      </a>
      <div className={styles.img_con}>
        <img src="landing_img2.png" alt="landing" />
      </div>
    </div>
  );
}
