import React, { useRef, useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollOut from "scroll-out";
import "./../globals.scss";
import "./../ani.css";
import styles from "./Counting.module.scss";

const Counting = () => {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const onIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={targetRef} className={styles.TP_con}>
      {isVisible ? (
        <div className={styles.threePoints}>
          <div className={styles.box}>
            <div className={styles.icon}>
              <img src="customers.png" alt="" />
            </div>
            <div className={styles.text_con}>
              <CountUp
                className={styles.text}
                start={0}
                end={3000}
                duration={3}
              />
              <div className={`${styles.text} ${styles.text2}`}>+</div>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.icon}>
              <img src="cargos.png" alt="" />
            </div>
            <div className={styles.text_con}>
              <CountUp
                className={styles.text}
                start={0}
                end={2000}
                duration={3}
              />
              <div className={`${styles.text} ${styles.text2}`}>+</div>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.icon}>
              <img src="building.png" alt="" />
            </div>
            <div className={styles.text_con}>
              <CountUp className={styles.text} start={0} end={6} duration={3} />
              <div className={`${styles.text} ${styles.text2}`}>yr</div>
            </div>
          </div>
        </div>
      ) : (
        <span>카운팅을 시작합니다!</span>
      )}
    </div>
  );
};

export default Counting;
